import * as Types from 'src/generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const SetAppMetadataDocument = gql`
    mutation setAppMetadata($input: SetAppMetadataInput!) {
  setAppMetadata(input: $input) {
    ... on AppMetadataResult {
      isSuccess
    }
    ... on ErrorResult {
      message
      type
      requestId
    }
  }
}
    `;
export type SetAppMetadataMutationFn = Apollo.MutationFunction<Types.SetAppMetadataMutation, Types.SetAppMetadataMutationVariables>;

/**
 * __useSetAppMetadataMutation__
 *
 * To run a mutation, you first call `useSetAppMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAppMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAppMetadataMutation, { data, loading, error }] = useSetAppMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAppMetadataMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAppMetadataMutation, Types.SetAppMetadataMutationVariables>) {
        return Apollo.useMutation<Types.SetAppMetadataMutation, Types.SetAppMetadataMutationVariables>(SetAppMetadataDocument, baseOptions);
      }
export type SetAppMetadataMutationHookResult = ReturnType<typeof useSetAppMetadataMutation>;
export type SetAppMetadataMutationResult = Apollo.MutationResult<Types.SetAppMetadataMutation>;
export type SetAppMetadataMutationOptions = Apollo.BaseMutationOptions<Types.SetAppMetadataMutation, Types.SetAppMetadataMutationVariables>;