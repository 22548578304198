import React from 'react'
import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'

import AdminUi from 'src/modules/adminUi/AdminUi'
import AuthGuard from 'src/components/AuthGuard'
import Header from 'src/components/Header'

const Layout = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #eee;
`

const StyledPaper = styled(Paper)(
  ({ theme }) =>
    css`
      width: 350px;
      height: 550px;
      margin: auto;
      border-radius: 5px;
      padding: ${theme.spacing(6, 4)};
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    `
)

const App = () => (
  <Layout>
    <StyledPaper elevation={4}>
      <Header />
      <AuthGuard>
        <AdminUi />
      </AuthGuard>
    </StyledPaper>
  </Layout>
)

export default App
