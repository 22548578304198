import { createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif'
const fontFamilySecondary = fontFamily

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

const spacingUnit = 8
export const spacing = (multiplier: number) => spacingUnit * multiplier

export const palette: PaletteOptions = {
  primary: {
    main: '#000',
    light: '#949494',
    xlight: '#fff',
    dark: '#141414',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    light: '#F5F5F5',
    dark: '#E9E9E9',
    contrastText: '#fff',
  },
  background: {
    light: '#F5F7FA',
    grey: '#212121',
    default: '#fff',
    dark: '#131313',
    paper: '#fff',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#01B288',
    light: '#CFFFD2',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#FF9800',
    xlight: '#fffaea',
  },
  error: {
    main: '#EF4334',
    light: '#FBE3EE',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#000000',
    secondary: '#949494',
    contrast: '#FFFFFF',
  },
  divider: '#F1F3F4',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    info: '#4B87FF',
    error: '#D2003C',
    success: '#84D584',
  },
  brand: {
    white: '#ffffff',
    orange: '#FFC425',
    red: '#EF4334',
    green: '#01B288',
    black: '#000000',
    grayscaleBlack: '#333333',
    darkGray: '#7F7F7F',
    gray: '#E9E9E9',
    lightGray: '#F1F3F4',
    offWhite: '#F9F9F9',
    gold: '#A79966',
    success: '#51A769',
    error: '#CF252F',
    info: '#333333',
  },
  programSolid: {
    ba: '#EBB243',
    bb: '#A9C651',
    bc: '#777130',
    bf: '#A9C651',
    bj: '#EDCC20',
    bp: '#EB333B',
    bs: '#3C8A93',
    bm: '#00B5E2',
    br: '#D58E7C',
    cr: '#D35B27',
    gr: '#000',
    tn: '#7A4CA9',
    ls: '#008C95',
    rp: '#49A6DB',
    sb: '#BF2A6E',
    sp: '#000000',
    tr: '#527B86',
  },
  programLight: {
    ba: '#FDF1DA',
    bb: '#F4FAE5',
    bc: '#EAE8D7',
    bf: '#F4FAE5',
    bj: '#FEF8DB',
    bp: '#FBD6D8',
    bs: '#D8E8E9',
    bm: '#C0B3E1',
    cr: '#F6DED4',
    br: '#FAEBE7',
    gr: '#E7E7E7',
    tn: '#E7DFF0',
    ls: '#DBEDF5',
    rp: '#DBEDF8',
    sb: '#F2D4E2',
    sp: '#E7E7E7',
    tr: '#DCE5E7',
  },
  type: 'light',
}

const theme = createMuiTheme({
  palette,
  spacing,
  props: {
    MuiWithWidth: {
      initialWidth: 'xs',
    },
  },
})

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily,
  fontWeightBold,
  fontWeightLight,
  fontWeightMedium,
  fontWeightRegular,
  fontFamilySecondary,
  h1: {
    ...theme.typography.h1,
    fontFamily: fontFamilySecondary,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    fontSize: theme.typography.pxToRem(80),
    lineHeight: theme.typography.pxToRem(72),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: theme.typography.pxToRem(43),
    },
  },
  h2: {
    ...theme.typography.h2,
    fontFamily: fontFamilySecondary,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    fontSize: theme.typography.pxToRem(64),
    lineHeight: theme.typography.pxToRem(56),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(36),
    },
  },
  h3: {
    ...theme.typography.h3,
    fontFamily: fontFamilySecondary,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    fontSize: theme.typography.pxToRem(52),
    lineHeight: theme.typography.pxToRem(47),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(28),
    },
  },
  h4: {
    ...theme.typography.h4,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(41),
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
      fontWeight: fontWeightRegular,
    },
  },
  h5: {
    ...theme.typography.h5,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(41),
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
      fontWeight: fontWeightMedium,
    },
  },
  h6: {
    ...theme.typography.h6,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(29),
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: fontWeightMedium,
    },
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: '-0.02em',
  },
  body2: {
    ...theme.typography.body2,
    fontFamily: fontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: '-0.02em',
  },
  button: {
    ...theme.typography.button,
    fontFamily: fontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: '-0.02em',
  },
  caption: {
    ...theme.typography.caption,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: '0.139em',
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Defaults can be deleted – these are used to test that Storybook Mui theming works
theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
      padding: theme.spacing(1.75, 2),
      borderRadius: `${theme.spacing(1.25)}px`,
    },
    contained: {
      boxShadow: 'unset',
      padding: theme.spacing(1.75, 2),
      borderRadius: `${theme.spacing(1.25)}px`,
      backgroundColor: theme.palette.brand.white,
    },
    containedPrimary: {
      color: theme.palette.brand.white,
      backgroundColor: theme.palette.brand.black,
      border: `1px solid ${theme.palette.brand.black}`,
    },
    containedSecondary: {
      color: theme.palette.brand.black,
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    outlined: {
      padding: theme.spacing(1.75, 2),
    },
    disabled: {
      border: `1px solid ${theme.palette.disabled.light}`,
    },
  },
  MuiInputBase: {
    root: {
      '& input': {
        padding: theme.spacing(2, 1.5),
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: theme.palette.brand.darkGray,
        '@media (hover: none)': {
          borderColor: theme.palette.brand.gray,
        },
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.brand.darkGray,
      },
      '&$disabled $notchedOutline': {
        color: theme.palette.brand.darkGray,
        borderColor: theme.palette.brand.gray,
      },
    },
  },
}

theme.props = {
  MuiTextField: {
    variant: 'outlined',
  },
}

export default theme
