import React from 'react'
import ReactDOM from 'react-dom'

import App from 'src/modules/App'
import * as serviceWorker from './serviceWorker'
import Providers from 'src/providers'

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
