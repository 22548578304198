import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { IdTokenContext } from 'src/utils/IdTokenContext'
import headerImageSrc from 'src/assets/images/logo.png'

const Logo = styled.img`
  width: 200px;
`

const Title = styled.h1(
  ({ theme }) =>
    css`
      font-size: ${theme.typography.pxToRem(22)};
      margin: ${theme.spacing(4, 0, 1)};
    `
)

const Header = () => {
  const idToken = useContext(IdTokenContext)

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Logo src={headerImageSrc} alt="Les Mills Logo" />
      <Title>Admin App - Connect</Title>
      <Typography variant="body1">{idToken?.email ?? ''}</Typography>
    </Box>
  )
}

export default Header
