import React from 'react'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '@auth0/auth0-react'

const UnauthorisedState = () => {
  const { logout } = useAuth0()

  const onLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Alert severity="error">Unauthorised User</Alert>
      <Box mt={2}>
        <Button onClick={onLogout}>Logout</Button>
      </Box>
    </Box>
  )
}

export default UnauthorisedState
