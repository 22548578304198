import React, { useState, useEffect, PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { useAuth0, Auth0Provider } from '@auth0/auth0-react'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles'

import theme from 'src/styles/theme'
import GlobalStyles from 'src/styles/GlobalStyles'
import ApolloProvider from 'src/utils/ApolloProvider'
import { IdTokenContext, IdTokenContextValue } from 'src/utils/IdTokenContext'

const Providers: React.FC = ({ children }) => (
  <ApolloProvider>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <>
            <GlobalStyles />
            <CssBaseline />
            {children}
          </>
        </StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </ApolloProvider>
)

const IdTokenWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0()
  const [idToken, setIdToken] = useState<IdTokenContextValue>(null)

  useEffect(() => {
    async function getIdToken() {
      setIdToken(await getIdTokenClaims())
    }

    if (isAuthenticated) {
      getIdToken()
    }
  }, [getIdTokenClaims, isAuthenticated])

  return (
    <IdTokenContext.Provider value={idToken}>
      {children}
    </IdTokenContext.Provider>
  )
}

const Auth0Wrapper = ({ children }: PropsWithChildren<{}>) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    redirectUri={window.location.origin}
  >
    <IdTokenWrapper>
      <Providers>{children}</Providers>
    </IdTokenWrapper>
  </Auth0Provider>
)

export default Auth0Wrapper
