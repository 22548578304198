import { PropsWithChildren, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import LoadingState from 'src/components/LoadingState'
import { IdTokenContext } from 'src/utils/IdTokenContext'
import UnauthorisedState from 'src/components/UnauthorisedState'

type AuthGuardProps = PropsWithChildren<{}>

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const idToken = useContext(IdTokenContext)

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    })
  }

  if (!isAuthenticated || isLoading) {
    return <LoadingState />
  }

  // Only users with email domain @lesmills.com have permissions to use the admin functionality
  // (also enforced on BE)
  const isAdminUser = /@lesmills.com$/.test((idToken?.email as string) ?? '')

  if (!isAdminUser) {
    return <UnauthorisedState />
  }

  return <>{children}</>
}

export default AuthGuard
