import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingState = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <CircularProgress size={50} thickness={2} />
  </Box>
)

export default LoadingState
